import React, { useContext } from "react";

import Footer from "../Actual_Components/Footer";
import { DataContext } from "../../MyContext";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
const VillasProjects = () => {
  const { villasProject } = useContext(DataContext);

  return (
    <div>
      <Helmet>
        <title>Top Villas for Sale in India</title>
        <meta
          name="description"
          content="Buy Deen Dayal Residential Plots/Lands for Sale in Gurgaon"
        />
        <link rel="canonical" href="https://www.100acress.com/villas/" />
      </Helmet>

      <div className="max-w-screen pt-4 sm:pt-2 md:pt-2" target="_top">
        <h1 className="mb-2 text-center text-2xl sm:text-xl md:text-2xl lg:text-3xl text-red-600 font-bold">
         India's Luxury Villas
        </h1>
        <h2 className="text-sm text-center sm:text-xl md:text-xl lg:text-sm font-normal lg:mx-20 md:mx-10 mx-5 sm:mx-4">
          Luxury villas in Gurgaon come with top-notch facilities and amenities.
          With privacy and exclusivity, the villa provides a haven for those
          seeking outstanding luxury living, where every detail caters to the
          wise tastes of its residents. They combine elegant design with modern
          comforts ideal for wise individuals selecting amazing living
          experiences.
        </h2>
      </div>

      <section className="flex flex-col items-center pt-2">
        <div className="grid max-w-md  grid-cols-1 px-8 sm:max-w-lg md:max-w-screen-xl md:grid-cols-2 md:px-4 lg:grid-cols-4 sm:gap-4 lg:gap-4 w-full">
          {villasProject.map((item, index) => {
            const pUrl = item.project_url;
            return (
              <Link to={`/${pUrl}/`} target="_top">
                <article
                  key={index}
                  className="mb-4 transition hover:scale-105 bg-white overflow-hidden rounded-xl  border text-gray-700 shadow-md duration-500 ease-in-out hover:shadow-xl"
                >
                  <div>
                    <img
                      src={item.frontImage.url}
                      alt="property In Gurugram"
                      className="w-full h-48 object-fit "
                    />
                  </div>
                  <div className="p-4">
                    <div className="pb-2">
                      <a className="text-[15px] font-semibold hover:text-red-600  duration-500 ease-in-out">
                        {item.projectName}
                      </a>

                      <br />
                      <a className="text-sm hover:text-red-600  duration-500 ease-in-out">
                        {item.city}, {item.state}
                      </a>
                    </div>

                    <ul className="box-border flex list-none items-center border-t border-b border-solid border-gray-200 px-0 py-2">
                      <li className="mr-4 flex items-center text-left">
                        <li className="text-left">
                          <span className="text-[13px] text-gray-400">
                            {item.projectAddress}
                          </span>
                          <p className="m-0 text-sm font-medium">{item.type}</p>
                        </li>
                      </li>
                    </ul>

                    <ul className="m-0 flex list-none items-center justify-between px-0  pb-0">
                      <li className="text-left">
                        <span className="text-sm font-extrabold text-red-600">
                          <span className="text-xl">₹ </span>
                          {item.minPrice}
                          {" - "}
                          {item.maxPrice} Cr
                        </span>
                      </li>

                      <li className="text-left">
                        <button
                          type="button"
                          className="text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-2 py-2  text-center me-2"
                        >
                          View Details
                        </button>
                      </li>
                    </ul>
                  </div>
                </article>
              </Link>
            );
          })}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default VillasProjects;
